.NewsletterSendForm {
    &__container {
        position: relative;
    }

    &__preview {
        width: 100%;
        height: 100%;
        min-height: 70vh;
        max-height: 85vh;
        border: 1px solid $grey-lighter;
    }

    &__link {
        position: absolute;
        right: 0;
        top: 0;
    }
}
