.image-buttons {
    position: relative;

    &:hover,
    &:focus-within {
        img {
            opacity: 0.2;
        }

        .image-buttons__double {
            opacity: 1;
        }
    }

    img {
        display: flex;
        position: relative;
        max-width: 100%;
    }

    &__single,
    &__double {
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        z-index: 1;
        width: 100%;
    }

    &__single {
        position: relative;
        height: 100%;

        span {
            position: absolute;
        }

        &--with-placeholder {
            padding: 0;
        }
    }

    &__double {
        position: absolute;
        height: 50%;
        color: $grey-darker;
        opacity: 0;
        background-color: rgba($light, 0.6);

        &:hover,
        &:focus {
            background-color: rgba($white, 0.8);
        }

        &:first-of-type {
            top: 0;
            bottom: unset;
        }

        &:last-of-type {
            bottom: 0;
            top: unset;
        }
    }
}
