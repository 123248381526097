.NotificationsProvider {
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    max-width: 800px;
    max-height: 100%;
    padding: 0.5rem 1rem;
    overflow-y: auto;
}
