.Editor {
    padding-bottom: 4rem;

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &__toolbar {
        position: sticky;
        clear: both;
        bottom: 1rem;
        padding: 0.5rem 1rem;
        z-index: 40;
        background-color: $white;
        border-radius: $card-radius;
        box-shadow: $card-shadow;

        .buttons {
            margin-bottom: 0;
            margin-top: 0.5rem;
        }
    }

    .public-DraftEditorPlaceholder-root {
        padding: 0 2rem;

        @media (min-width: 1024px) {
            padding: 0;
        }
    }
}
