.ImageField {
    &__tab {
        background: none;
        border: 0 none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px;
        padding: 0.5em 1em;
        outline: none;
        border-bottom-color: $grey-lighter;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        color: $grey-dark;
        vertical-align: top;
        font-size: 1rem;

        &.is-active {
            border-bottom-color: $link;
            color: $link;
        }
    }

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 1rem 0;
        min-height: 70vh;

        > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
