.ImageCollection {
    width: 100%;

    .card {
        display: flex;
        align-items: center;
        padding: 1em;
        width: 100%;
        height: 100%;
    }
}
