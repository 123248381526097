.NewsletterSectionTemplateCollection {
    .card {
        height: 100%;
        margin-bottom: 1rem;
        text-align: left;

        a,
        button {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 1rem;
        }
    }
}
