.PostCreateOrUpdateForm {
    &__background {
        background-color: $white;
    }

    &__control {
        &--title {
            margin-bottom: 0.2rem;
        }
    }

    &__input {
        &--title,
        &--subtitle {
            width: 100%;
            line-height: 1.2;
        }

        &--title {
            font-weight: 600;
            color: $primary;
            font-size: 2em;

            &::placeholder {
                color: $primary;
            }
        }

        &--subtitle {
            font-weight: 600;
            color: $grey;
            font-size: 1.35em;

            &::placeholder {
                color: $grey;
            }
        }

        &--author,
        &--date {
            font-weight: 600;
            font-size: 1em;
            color: $grey-darker !important;

            &::placeholder {
                color: $grey-darker !important;
            }
        }
    }
}
