.ImageCreateForm {
    position: relative;
    text-align: center;
    padding: 1rem;

    &__container {
        display: flex;
        align-items: center;
        height: 100%;
        border: 2px dashed $grey-lighter;
        padding: 3rem 0;
    }

    &__inner {
        width: 100%;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        padding: 3rem 0;
    }
}
