$blue: #00aac6;
$orange: #fe6b0c;
$red: #cf3239;
$grey-darkest: #222222;
$grey-darker: #333333;
$grey-dark: #444444;

.NewsletterCreateOrUpdateForm {
    &__container {
        font-family: $newsletter-font-family;
    }

    &__title {
        margin: 0 0 8px;
        text-transform: uppercase;
        color: $blue;
        font-size: 14px;
        line-height: 21px;

        &::placeholder {
            color: rgba($blue, 0.3);
        }
    }

    &__subtitle {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0;
        padding-bottom: 4px;

        span {
            text-transform: uppercase;
            color: $grey-darker;
            font-size: 32px;
            line-height: 30px;

            &::placeholder {
                color: $grey-darker;
            }
        }

        small {
            padding-left: 10px;
            text-align: right;
            text-transform: uppercase;
            color: $grey-dark;
            font-size: 16px;
            line-height: 16px;

            &::placeholder {
                color: $grey-dark;
            }
        }

        @media (max-width: 678px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__overture {
        margin: 0 0 14px;
        color: $grey-dark;
        text-transform: uppercase;
        font-size: 13px;
        line-height: 20px;

        &::placeholder {
            color: $grey-dark;
        }
    }

    &__bar {
        height: 2px;
        background: linear-gradient(to right, $blue 0%, $blue 33%, $orange 33%, $orange 66%, $red 66%, $red 100%);
    }

    &__field {
        &--title,
        &--date {
            display: inline;
        }
    }

    &__control {
        &--title,
        &--date {
            display: inline;
        }
    }

    &__input {
        &--title,
        &--date {
            text-transform: uppercase;
            color: $blue;
            font-size: 14px;
            line-height: 21px;
            font-weight: bold;
            font-family: $family-monospace;

            &::placeholder {
                color: $blue;
            }
        }

        &--title[value=''] {
            min-width: 6ch;
        }

        &--date[value=''] {
            min-width: 4ch;
        }

        &--summary {
            line-height: 20px;
            color: $grey-darkest;
            font-size: 14px;

            &::placeholder {
                color: $grey-darkest;
            }
        }
    }

    .buttons {
        justify-content: flex-end;
        margin: 0;
    }
}
