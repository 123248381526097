$blue: #009ec5;
$red: #b4131d;
$orange: #df6b13;
$green: #00c049;

$primary: $blue;

$family-sans-serif: 'Open Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial',
    sans-serif;
$family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

$text-strong: inherit;
$strong-weight: 600;
$label-weight: 600;
$navbar-item-img-max-height: 2.35rem;
$footer-background-color: transparent;
$card-content-padding: 1rem;
$modal-content-width: 960px;
$tag-radius: 0;

$newsletter-font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
    'Noto Sans', Roboto, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
