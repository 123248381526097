.MemberCreateOrUpdateForm {
    &__field {
        &--lattes_url,
        &--email {
            display: flex;

            .label {
                margin: 0 0.4em 0 0;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 2.5em;
                height: 2.5em;
                border: 2px solid $white;
                border-radius: 2.5em;

                svg {
                    width: 1.25em;
                    max-width: 100%;
                    max-height: 1.25em;
                }

                path,
                polygon {
                    fill: $white;
                }
            }
        }

        &--lattes_url {
            margin-bottom: 0.2em;
        }
    }

    &__control {
        &--lattes_url,
        &--email {
            display: flex;
            flex-grow: 1;
        }
    }

    &__input {
        &--title,
        &--subtitle {
            width: 100%;
            line-height: 1.2;
        }

        &--title {
            font-weight: bold;
            color: $white;
            font-size: 1.35em;

            &::placeholder {
                color: $white;
            }
        }

        &--title-complement {
            color: $white;
            font-size: 0.8em;

            &::placeholder {
                color: $white;
            }
        }

        &--subtitle {
            font-weight: 600;
            color: $white;
            font-size: 1em;
            line-height: 1.5;

            &::placeholder {
                color: $white;
            }
        }

        &--lattes_url,
        &--email {
            width: 100%;
            font-size: 0.8em;
            line-height: 1.2;
            text-transform: uppercase;
            color: $white;
            font-weight: 600;
        }
    }
}
