.ImageCollectionItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    &__thumbnail {
        width: 100%;
        font-size: 0.8rem;
        margin-top: 1em;
        word-break: break-all;
    }
}
