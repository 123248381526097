.ImageSelectForm {
    width: 100%;
    align-self: flex-start;
    position: relative;

    &__container {
        display: flex;
        height: 100%;
        padding: 1rem 0;
    }

    &__selected {
        padding-right: 2rem;
        background-color: $white;
        max-width: 25%;
        overflow: auto;
    }

    &--with-selected {
        max-width: 75%;
    }
}
