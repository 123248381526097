.actions {
    clear: both;

    &--sticky {
        position: sticky;
        bottom: 1rem;
        z-index: 30;
        pointer-events: none;

        a,
        button {
            pointer-events: initial;
        }
    }

    &--reset {
        padding: 4rem 2rem 0;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }
}
