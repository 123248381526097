.ImageEditorForm {
    &__image {
        max-width: unset;
        position: fixed;
        visibility: hidden;
    }

    &__container {
        canvas,
        img {
            max-width: 100%;
        }
    }

    &__wrapper {
        position: relative;
        min-height: 70vh;
        max-height: 85vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__cropped {
        display: block;
        position: relative;
        z-index: 19;
        max-width: 100%;
        height: auto;
        margin: 0 auto 1rem;
        outline: 1px dashed $grey;
    }
}
