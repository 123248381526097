.OpinionCreateOrUpdateForm {
    &__background {
        background-color: $white;
    }

    &__input {
        &--title,
        &--subtitle {
            width: 100%;
            line-height: 1.2;
        }

        &--title {
            font-weight: bold;
            color: $white;
            font-size: 1.35em;

            &::placeholder {
                color: $white;
            }
        }

        &--subtitle {
            font-weight: 600;
            color: $white;
            font-size: 1em;

            &::placeholder {
                color: $white;
            }
        }

        &--person-name,
        &--person-job-title {
            width: 100%;
            font-size: 0.8em;
            line-height: 1.2;
            text-transform: uppercase;
            color: $white;
        }

        &--person-name {
            font-weight: bold;
        }

        &--person-job-title {
            font-weight: 600;
        }

        &--author,
        &--date {
            font-weight: 600;
            font-size: 1em;
            color: $grey-darker !important;

            &::placeholder {
                color: $grey-darker !important;
            }
        }
    }
}
