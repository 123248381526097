$aside-width: 20%;

.DefaultLayout {
    &__top-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        width: 100%;
        background: linear-gradient(
            to right,
            $blue 0%,
            $blue 33.333%,
            $red 33.333%,
            $red 66.666%,
            $orange 66.666%,
            $orange 100%
        );
        z-index: 100;
    }

    &__header {
        pointer-events: none;
        padding-top: 4px;

        a,
        button {
            pointer-events: initial;
        }

        &.navbar {
            background-color: transparent;
            z-index: 100;
        }

        .navbar-brand {
            justify-content: space-between;
            width: 100%;
        }

        .navbar-item {
            margin: 0;
            background: transparent;
            border: 0 none;
        }

        .navbar-burger {
            display: block;
            margin: 0;
            margin-left: unset;
            padding: 0;
            background-color: transparent;
            border: 0 none;

            &:hover,
            &:focus {
                background-color: transparent;
            }

            span {
                height: 2px;
            }
        }
    }

    &__main {
        width: 100%;
        min-height: 100vh;
        padding: $navbar-height 0 0;

        @media (min-width: 1024px) {
            width: 100% - $aside-width;
        }

        &--hidden {
            width: 100%;
        }
    }

    &__aside {
        position: fixed;
        top: 0;
        right: 0;
        width: $aside-width;
        min-width: 15rem;
        max-width: 100%;
        height: 100%;
        padding: $navbar-height 2em 0;
        z-index: 90;
        overflow-y: auto;
        background-color: $white;
        border-left: 1px solid $light;

        &--hidden {
            transform: translateX(100%);
        }
    }

    &__container {
        margin: 0 auto;
        padding: 3rem 0 6rem;

        &--contained {
            max-width: 960px;
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &--full-width {
            max-width: 100%;

            .DefaultLayout__title,
            .contained {
                max-width: 1440px;
                padding-left: 2rem;
                padding-right: 2rem;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__title {
        flex-wrap: wrap;

        > div {
            max-width: 100%;
            flex-grow: 1;
            flex-shrink: 1;
        }
    }

    &__navigation {
        padding: 1rem 0 2rem;
    }

    &__full-width-core {
        background-color: $white;
    }
}
