.NewsletterSectionTemplateCollectionItem {
    font-family: $newsletter-font-family;
    width: 100%;
    max-width: 750px;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
    }

    &__item-title {
        text-transform: uppercase;
    }

    &__link-text {
        display: inline-block;
        vertical-align: middle;
        font-family: $newsletter-font-family !important;
        position: relative;
        height: 21px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 26px !important;
        margin-bottom: 0.25em;

        &::before {
            content: '';
            width: 21px;
            height: 21px;
            background: url('../../images/target_blank.png') center 5px scroll no-repeat currentColor;
            background-size: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        span {
            line-height: 21px;
        }
    }

    &--simple {
        margin: 0 0 20px !important;
    }

    &--highlighted {
        margin: 0 !important;
    }
}
