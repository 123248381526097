.Dialog {
    &--full-width {
        width: calc(100vw - 60px);
    }

    &--no-padding {
        padding: 0;
    }

    .actions--sticky {
        bottom: 0;
        margin-top: 1.5rem;
    }

    .ImageCollection__inner {
        margin-bottom: 1rem;
    }
}
