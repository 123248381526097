.UnauthenticatedLayout {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem 1rem;
    text-align: center;
    overflow: hidden;

    &::after {
        position: absolute;
        z-index: -1;
        content: 'CDTS';
        font-size: 20rem;
        font-size: 40vw;
        color: $grey-lightest;
        font-weight: 600;
    }

    &__top-bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 2rem;
        width: 100%;
        background: linear-gradient(to right, $blue 0%, $red 50%, $orange 100%);
        z-index: 40;
    }

    .box {
        width: 360px;
        margin: 0 auto;
        text-align: left;
    }

    .subtitle {
        line-height: 1.5;
        margin-bottom: 0.5rem;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
        text-align: left;
    }

    &__image {
        max-width: 64px;
        height: auto;
        margin-right: 0.75rem;
    }
}
