.NewsletterSectionItemFormFields {
    padding: 20px 4px 14px;
    border-top: 1px solid #eeeeee;

    &:first-of-type {
        padding-top: 0;
        border-top: 0 none;
    }

    &__image {
        margin-bottom: 8px;

        img {
            display: flex;
            width: 100%;
        }
    }

    &__level {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__field {
        &--link_text {
            position: relative;
            padding-left: 21px;

            &::before {
                content: '';
                width: 21px;
                height: 21px;
                background: url('../../../images/target_blank.png') center 5px scroll no-repeat currentColor;
                background-size: 10px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        &--link_href {
            width: calc(100% - 10px);
        }

        &--title.is-cdts {
            textarea {
                text-indent: 26px;
                background-image: url('/logo192.png');
                background-repeat: no-repeat;
                background-position: 0 2px;
                background-size: 22px;
            }
        }
    }

    &__input {
        &--title {
            width: 100%;
            font-size: 21px;
            line-height: 1.2;
            font-weight: bold;
            margin: 4px 0 0;
            color: #222222;

            &::placeholder {
                color: #222222;
            }
        }

        &--text {
            margin: 14px 0;
            line-height: 20px;
            color: #222222;
            font-size: 14px;

            &::placeholder {
                color: #222222;
            }
        }

        &--link_text,
        &--link_href {
            font-size: 13px;
            padding: 2px 6px;
        }

        &--link_text {
            background-color: #e7e7e7;
            text-transform: uppercase;
            font-weight: 700;
            color: currentColor;
            font-family: $family-monospace;

            &::placeholder {
                color: currentColor;
            }

            &[value=''] {
                min-width: 13ch;
            }
        }

        &--link_href {
            box-sizing: border-box;
            padding: 5px 0 0 6px;
            color: currentColor;
            border: 0 none;
            text-decoration: underline;

            &:hover,
            &:focus {
                border: 0 none;
            }

            &::placeholder {
                text-decoration-color: transparent;
            }
        }
    }

    .checkbox {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $grey-darker;
        cursor: auto;

        input {
            margin-right: 8px;
        }
    }
}
