.editor-block {
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover,
    &:focus-within {
        border: 1px solid $grey-light;

        img,
        blockquote,
        iframe {
            opacity: 0.2;
        }

        .button {
            opacity: 1;
        }
    }

    .button {
        background: none;
        border: 0 none;
        border-radius: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background-color: rgba($light, 0.6);

        &:hover,
        &:focus {
            background-color: rgba($white, 0.8);
        }

        &:first-of-type {
            top: 0;
            bottom: unset;
        }

        &:last-of-type {
            bottom: 0;
            top: unset;
        }
    }
}
