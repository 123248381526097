textarea {
    resize: none !important;
}

fieldset[disabled] {
    .button.is-danger {
        background-color: inherit;
    }

    .variant-input,
    .Editor {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none;
        text-decoration: none;
        cursor: not-allowed;
    }

    .button-reset,
    .NewsletterSectionsField {
        display: none;
    }

    .image-buttons img {
        opacity: 1;
    }

    .editor-block button,
    .Editor__toolbar {
        display: none;
    }

    .editor-block {
        &:hover,
        &:focus-within {
            border: 0 none;

            img,
            blockquote,
            iframe {
                opacity: inherit;
            }
        }
    }
}

.variant-field {
    position: relative;

    .help {
        position: absolute;
        left: 0;
        margin: 0;
        padding: 0.25rem 0.5rem;
        background-color: $danger;
        color: #fff;
        border: 1px solid #fff;
        text-transform: initial;
        z-index: 10;
        font-weight: 600;
    }
}

.variant-input {
    box-sizing: content-box;
    padding: 0;
    border: 0;
    width: 100%;
    background: transparent;
    border: 1px dashed transparent;
    font-family: inherit;

    &:hover,
    &:focus {
        outline: 0;
        box-shadow: none;
        border-color: #ccc;
    }

    &:active {
        border-color: $primary;
    }
}
