.NewsletterSectionField {
    margin-bottom: 2rem;

    &:first-of-type {
        margin-top: 0;
    }

    &--highlighted {
        border: 2px solid currentColor;
        padding: 8px;

        .NewsletterSectionItemFormFields__input--title {
            color: currentColor;
        }
    }

    &__title {
        margin: 0 0 10px;

        span {
            display: inline-block;
            background-color: currentColor;
        }

        strong {
            margin: 2px 6px;
            font-size: 13px;
            line-height: 24px;
            text-transform: uppercase;
            color: #ffffff;
        }

        img {
            display: block;
            color: currentColor;
            text-align: center;
            font-weight: 700;
            font-size: 13px;
            text-transform: uppercase;
            max-width: 100%;
        }
    }

    .button {
        color: $grey-dark;
    }
}
