.collection-image-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0.2rem 0;

    &__image {
        flex-grow: 1;
        flex-shrink: 0;
        width: 100%;
        max-width: 75px;
        margin-right: 0.6rem;
    }

    &__title {
        margin-bottom: 0.2rem;
    }

    &__text {
        color: $grey;
    }
}
