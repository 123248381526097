.button-reset {
    background: none;
    border: 0 none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.is-justified-center {
    justify-content: center;
}
