::placeholder {
    opacity: 0.5;
}

html {
    background-color: $light;
}

.is-clipped .navbar-burger {
    visibility: hidden;
}
