/* BULMA */

input[type='range'] {
    appearance: auto;
    -moz-appearance: auto;
    -webkit-appearance: auto;
}

.loader {
    margin: 0 auto;
}

.modal-card {
    border-bottom-left-radius: $modal-card-foot-radius;
    border-bottom-right-radius: $modal-card-foot-radius;
}

.table th {
    font-weight: 600;
}

.select {
    width: 100%;
}

.is-danger input,
.is-danger textarea {
    border-color: $red;
}

// Button tabs

.tabs {
    .button {
        align-items: center;
        border-bottom-color: $tabs-border-bottom-color;
        border-bottom-style: $tabs-border-bottom-style;
        border-bottom-width: $tabs-border-bottom-width;
        color: $tabs-link-color;
        display: flex;
        justify-content: center;
        margin-bottom: -#{$tabs-border-bottom-width};
        padding: $tabs-link-padding;
        vertical-align: top;

        &:hover {
            border-bottom-color: $tabs-link-hover-border-bottom-color;
            color: $tabs-link-hover-color;
        }
    }

    &.is-toggle {
        .button {
            border-color: $tabs-toggle-link-border-color;
            border-style: $tabs-toggle-link-border-style;
            border-width: $tabs-toggle-link-border-width;
            margin-bottom: 0;
            position: relative;

            &:hover {
                background-color: $tabs-toggle-link-hover-background-color;
                border-color: $tabs-toggle-link-hover-border-color;
                z-index: 2;
            }
        }

        li {
            .button {
                border-radius: 0;
            }

            &:first-child .button {
                border-top-left-radius: $tabs-toggle-link-radius;
                border-bottom-left-radius: $tabs-toggle-link-radius;
            }

            &:last-child .button {
                border-top-right-radius: $tabs-toggle-link-radius;
                border-bottom-right-radius: $tabs-toggle-link-radius;
            }

            &.is-active .button {
                background-color: $tabs-toggle-link-active-background-color;
                border-color: $tabs-toggle-link-active-border-color;
                color: $tabs-toggle-link-active-color;
                z-index: 1;
            }
        }
    }
}

/* REACT-DATEPICKER */

.rdt {
    input {
        @extend %input;
        width: 100%;
    }

    &--reset input {
        box-sizing: content-box;
        padding: 0;
        width: 100%;
        background: transparent;
        border: 1px dashed transparent;
        border-radius: 0;
        color: unset;
        font-family: unset;
        font-size: unset;
        height: unset;
        line-height: unset;

        &:hover,
        &:focus {
            outline: 0;
            box-shadow: none;
            border-color: #ccc;
        }

        &:active {
            border-color: $blue;
        }

        &::placeholder {
            color: unset;
        }
    }
}

.rdtPicker {
    font-size: 0.8rem;
    top: 100%;
    top: calc(100% + 0.5rem);
    left: 0;

    table th {
        font-weight: 600;
    }

    table td:not([align]),
    table th:not([align]) {
        text-align: center;
        vertical-align: middle;
    }

    td.rdtActive,
    td.rdtActive:hover {
        background-color: $primary;
    }

    td.rdtToday::before {
        border-bottom-color: $primary;
    }

    .rdtCounter .rdtCount {
        height: auto;
    }

    .rdtTimeToggle {
        font-weight: 600;
    }

    .rdtTime .rdtSwitch {
        font-weight: 600;
        cursor: pointer;

        &:hover {
            background-color: #eee;
        }
    }
}

/* CDTS */

// Cover

.cdts-cover__image {
    .button {
        color: $grey-darker;
        font-weight: 600;
    }

    .image-buttons__single {
        color: $white;

        &:hover,
        &:focus {
            background-color: rgba(0, 0, 0, 0.25);
        }
    }
}

// Magazine

.cdts-magazine__body {
    .paragraph {
        display: block;
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
    }

    figure:not(.cdts-atomic) {
        border: 1px dashed $red;
    }
}

.cdts-magazine-aside {
    min-height: auto !important;
}
